import React, { useEffect, useState } from 'react'
import { useBoolean, useInterval, useTimeoutFn } from 'react-use'

import { ProcessingModal, ProcessingModalProps } from './ProcessingModal'

type UseProcessingModalProps = Pick<
  ProcessingModalProps,
  'isOpen' | 'label' | 'description' | 'isCancelDisabled' | 'onClose'
> & {
  actionPromise: () => Promise<void>
  delay?: number
  waitAfterCompletion?: number
}

type useProcessingModalType = {
  component: JSX.Element
  toggleIsRunning: (nextValue: boolean) => void
  setIsCancelDisabled: React.Dispatch<React.SetStateAction<boolean>>
  cancel: () => void
  timerIsReady: () => boolean | null
  resetTimer: () => void
}

const MAX_PROGRESS_VALUE = 100
const PROGRESS_STEP = 5
const STEP_COUNT = MAX_PROGRESS_VALUE / PROGRESS_STEP
const DEFAULT_DELAY = 5000

export const useProcessingModal = ({
  onClose,
  isOpen,
  delay = DEFAULT_DELAY,
  actionPromise,
  label,
  description,
  waitAfterCompletion = 2000,
}: UseProcessingModalProps): useProcessingModalType => {
  const [isCancelDisabled, setIsCancelDisabled] = useBoolean(false)
  const [processValue, setProcessValue] = useState(0)
  const [isRunning, toggleIsRunning] = useBoolean(true)
  const [isProgressFinished, setIsProgressFinished] = useBoolean(false)
  const [error, setError] = useState(null)

  const timeoutCallback = async (): Promise<void> => {
    setIsCancelDisabled(true)
    toggleIsRunning(false)
    await actionPromise?.().catch(setError)
    toggleIsRunning(true)
  }

  useInterval(
    () => {
      setProcessValue((val) => val + PROGRESS_STEP)
      setIsProgressFinished(processValue >= MAX_PROGRESS_VALUE)
    },
    isRunning ? delay / STEP_COUNT : null,
  )

  const [isReady, cancel, reset] = useTimeoutFn(timeoutCallback, delay)

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (isProgressFinished) {
      toggleIsRunning(false)
      timerId = setTimeout(onClose, waitAfterCompletion)
    }

    return () => {
      window.clearTimeout(timerId)
    }
  }, [isProgressFinished, onClose, toggleIsRunning, waitAfterCompletion])

  const component = (
    <ProcessingModal
      isOpen={isOpen}
      error={error}
      description={description}
      onClose={onClose}
      isProgressFinished={isProgressFinished}
      cancel={cancel}
      label={label}
      processValue={processValue}
      isCancelDisabled={isCancelDisabled}
    />
  )

  return {
    component,
    toggleIsRunning,
    setIsCancelDisabled,
    cancel,
    timerIsReady: isReady,
    resetTimer: reset,
  }
}
